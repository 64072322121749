<template>
    <tr v-if="item">
      <td>
        <router-link
            :to="{
              name: 'animal',
              params: { nombre: item.nombre_animal, id: item.id_animal },
            }"
          >{{ item.id_animal }}: {{ item.nombre_animal }}
          </router-link>
      </td>
      <td>{{ item.id_item }}: {{ item.tipo_item }}</td>
      <td>
        <span v-if="item.tipo_item == 'actuacion'">
          {{ item.tipo_actuacion }}: {{ item.nombre_actuacion }}
        </span>
        <span v-else-if="item.tipo_item == 'tratamiento'">
          {{ item.tratamiento }}
        </span>
        <span v-else-if="item.tipo_item == 'visita'">
          {{ item.tipo_visita }} {{ item.nombre_cv }}
        </span>
      </td>
      <td>
        <span v-if="item.tipo_item == 'actuacion'">
          {{ fecha_mostrar(item.fecha_ac) }}
        </span>
        <span v-else-if="item.tipo_item == 'tratamiento'">
          {{ fecha_mostrar(item.fecha_inicio) }}
        </span>
        <span v-else-if="item.tipo_item == 'visita'">
          {{ fecha_mostrar(item.fecha_visita) }}
        </span>
      </td>
      <td>
        {{ fecha_mostrar(item.deleted_at) }}
      </td>
      <td>{{ item.id_user }}: {{ item.nombre_usuario }}</td>
      <td>
        <button class="btn btn-success btn-sm" @click="restaurar">Restaurar</button>
      </td>
      <td>
        <button v-if="false" class="btn btn-danger btn-sm" @click="eliminar" title="Eliminar definitivamente">
          Eliminar
        </button>
      </td>
    </tr>
</template>

<script>
export default {
  name: "PapeleraItem",
  data() {
    return {
      boxOne: "",
      boxTwo: "",
      accion: false,
    };
  },
  props: {
    item: { default: false },
  },
  computed: {
    formu(){
      if(this.item){
        let formu = {
          id_trash: this.item.id,
          id_item: this.item.id_item,
          tipo_item: this.item.tipo_item,
          id_animal: this.item.id_animal,
          accion: this.accion
        }
        return formu;
      }
      return false
    },

  },
  methods: {
    restaurar(){
      this.$bvModal
        .msgBoxConfirm("Confirmar que quieres restaurar.", {
          title: "Confirmar",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "Sí, restaurar",
          cancelTitle: "No, cancelar",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if(value){
            this.accion = 'restaurar';
            console.log('enviar restaurar')
            this.$emit('enviar', this.formu)
          } 
          else{
            this.accion = false
            console.log('accion cancelada')
          } 
        })
    },
    eliminar(){
      this.$bvModal
        .msgBoxConfirm("Confirmar que quieres borrar definitivamente.", {
          title: "Confirmar",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sí, borrar",
          cancelTitle: "No, cancelar",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if(value){
            this.accion = 'delete';
            console.log('enviar delete')
          } 
          else{
            this.accion = false
            console.log('accion cancelada')
          } 
        })
    },
    fecha_mostrar(fecha){
      if(fecha){
        let _fecha = fecha.split(' ');
        let its = _fecha.length
        let hora = ''
        if(its>0) fecha = _fecha[0];
        if(its>1) hora = _fecha[1];
        if(fecha) fecha=this.$root.fechaInvertir(fecha)
        if(hora) fecha += ' ' + hora;
      }
      return fecha
    },
  },
};
</script>

