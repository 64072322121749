<template>
  <div>
    <b-container v-if="usuario.es_admin">
      <h1>Papelera</h1>
    <table class="table w-auto m-auto" v-if="lista_papelera.length">
      <thead>
        <tr>
          <th scope="col">Animal</th>
          <th scope="col">Tipo</th>
          <th scope="col"></th>
          <th scope="col">Fecha</th>
          <th scope="col">Anulado el</th>
          <th scope="col">Por usuario</th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <PapeleraItem 
          v-for="(item, index) in lista_papelera" :key="index"
          :item="item"
          @enviar="enviar"
          >
        </PapeleraItem>
      </tbody>
    </table>

    <div v-else>
      Nada anulado en la papelera.
    </div>
    </b-container>
    <b-container v-else>
      <NoPermisos></NoPermisos>
    </b-container>
  </div>
</template>

<script>
import PapeleraItem from "@/components/PapeleraItem";
import NoPermisos from "@/components/NoPermisos";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: papeleraMapGetters, mapActions: papeleraMapActions } =
  createNamespacedHelpers("papelera");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
const { mapGetters: historialMapGetters, mapActions: historialMapActions } =
  createNamespacedHelpers("historial");

export default {
  name: "Papelera",
  data() {
    return {
      
    };
  },
  components:{
    PapeleraItem,
    NoPermisos
  },
  mounted() {
    this.loadData();
    document.title = "Papelera";
  },
  computed: {
    ...userMapGetters(["user_loged_in", "usuario", "nombre_usuario", 'es_admin']),
    ...papeleraMapGetters(["lista_papelera"]),
    ...historialMapGetters(["id_animal"]),
  },
  methods: {
    loadData() {
      //if (this.user_loged_in) {
      this.GET_PAPELERA();
      console.log("entro en papelera");
      //}
    },
    enviar(data=false){
      console.log('enviar');
      if(data){
        console.log(data);
        this.$http
          .post(
            this.$bambuvet +"/trash.php",
            JSON.stringify(data),
            {emulateJSON: true, withCredentials: true}
          )
          .then((res) => {
            let resp = res.body;
            console.log(resp)
            if(resp.msg=='ok'){
              console.log(resp.msg)
              this.REMOVER_ITEM(data);

              if(data.id_animal == this.id_animal){
                if(resp.item_restaurado){
                  if(data.tipo_item == 'actuacion')
                    this.ACTUACION_REALIZADA_INSERTH(resp.item_restaurado);
                  if(data.tipo_item == 'tratamiento')
                    this.TRATAMIENTO_INSERTH(resp.item_restaurado);
                  if(data.tipo_item == 'visita')
                    this.VISITA_INSERTH(resp.item_restaurado);
                }
              }
            }
          });
      }
    },
    ...papeleraMapActions(["GET_PAPELERA","REMOVER_ITEM"]),
    ...historialMapActions(["ACTUACION_REALIZADA_INSERTH",'TRATAMIENTO_INSERTH','VISITA_INSERTH']),
  },
};
</script>

