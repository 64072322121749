<template>
  <div class="d-flex justify-content-center">
      <b-alert show variant="warning">
          <h4 class="alert-heading">Ops</h4>
          <p>
            Vaya, parece que no tienes permisos para andar por aquí.
          </p>
          <hr>
          
        </b-alert>
  </div>
</template>

<script>
export default {
  name:"NoPermisos",
}
</script>