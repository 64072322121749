<template>
  <div v-if="usuarios">
    <b-container>
    <UsuariosLista
      :usuarios_prop="usuarios"
    ></UsuariosLista>
    </b-container>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: usuariosMapGetters, mapActions: usuariosMapActions } =
  createNamespacedHelpers("usuarios");
import UsuariosLista from "@/components/UsuariosLista";
export default {
  name: "Usuarios",
  components: {
    UsuariosLista,
  },
  mounted(){
    if(!this.usuarios){
      this.GET_USUARIOS();
    }
  },
  methods: {
    ...usuariosMapActions(["GET_USUARIOS"]),
  },
  computed: {
    ...usuariosMapGetters(["usuarios"]),
  },
};
</script>

<style>
</style>