<template>
  <div v-if="usuarios_prop">
    Usuarios lista
    <table class="table table-striped table-hover w-auto m-auto">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nombre</th>
          <th scope="col">E-mail</th>
          <th scope="col">Rol</th>
          <th scope="col">Activo</th>
          <th scope="col">Alta</th>
          <th scope="col">
            <button @click="addUsuario()" class="btn btn-sm btn-info">
              <b-icon icon="person-plus"></b-icon>
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(usuario, index) in usuarios_prop"
          :key="index"
          :usuario_prop="usuario"
        >
          <td>
            {{ usuario.id_usuario }}
          </td>
          <td>
            {{ usuario.nombre_usuario }}
          </td>
          <td>
            {{ usuario.email }}
          </td>
          <td>
            <b-icon
              icon="emoji-sunglasses-fill"
              class="text-success"
              v-if="usuario.nivel == '1'"
            ></b-icon>
            <b-icon
              icon="emoji-sunglasses"
              class="text-success"
              v-if="usuario.nivel == '2'"
            ></b-icon>
            <b-icon
              icon="emoji-smile"
              class="text-success"
              v-if="usuario.nivel == '3'"
            ></b-icon>
            {{ usuario.nivel == 2 || usuario.nivel == 1 ? "Admin" : "Usuario" }}
          </td>
          <td>
            <b-icon
              icon="check"
              class="text-success"
              v-if="usuario.activo == '1'"
            ></b-icon>
            <b-icon icon="x" class="text-danger" v-else></b-icon>
          </td>
          <td>
            {{ usuario.fecha_alta }}
          </td>
          <td>
            <button @click="getUsuario(usuario)" class="btn btn-sm btn-secondary">
              ver
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <UsuarioForm
      v-if="usuario_activo || accion_form == 'insert'"
      :usuario_prop="usuario_activo"
      :show_prop="show"
      :accion_form_prop="accion_form"
      v-on:cerrar="cancelarUsuario"
    ></UsuarioForm>
  </div>
</template>

<script>
//import Usuario from "@/components/Usuario";
import UsuarioForm from "@/components/UsuarioForm";
export default {
  name: "UsuariosLista",
  data() {
    return {
      usuario_activo: false,
      show: false,
      accion_form: "update",
    };
  },
  components: {
    UsuarioForm,
  },
  props: {
    usuarios_prop: { default: false },
  },
  methods: {
    getUsuario(usuario) {
      //alert(usuario.nombre_usuario)
      this.show = true;
      this.usuario_activo = usuario;
      this.accion_form = "update";
    },
    addUsuario() {
      //alert(usuario.nombre_usuario)
      this.show = true;
      this.usuario_activo = false;
      this.accion_form = "insert";
    },
    cancelarUsuario() {
      this.usuario_activo = false;
      this.accion_form = "update";
    },
  },
};
</script>

<style>
</style>