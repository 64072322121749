<template>
  <div>
    <b-modal
      size="lg"
      ref="modal-visita"
      v-model="show"
      title="Usuario"
      :hide-footer="true"
    >
      <form @submit="onSubmit" autocomplete="off" id="pepe">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="nombre_usuario"
                placeholder="Nombre"
                autocomplete="off"
                required
                v-model="formu.nombre_usuario"
              />
              <label for="nombre_usuario">Nombre</label>
            </div>

            <div class="form-floating mb-3">
              <input
                type="email"
                class="form-control"
                id="email_"
                placeholder="E-mail"
                required
                v-model="formu.email"
              />
              <label for="email_">E-mail</label>
            </div>

            <div class="form-floating mb-3">
              <select
                class="form-select"
                id="rol"
                aria-label="Role"
                required
                v-model="formu.nivel"
              >
                <option value="1" :disabled="disabledAsignarSuperAdmin">SuperAdmin</option>
                <option value="2" :disabled="disabledAsignarAdmin">Admin</option>
                <option value="3" :disabled="disabledAsignarUsuario">Usuario</option>
              </select>
              <label for="rol">Nivel (Rol)</label>
            </div>

            <div class="form-floating mb-3" id="pepe">
              <input
                type="text"
                class="form-control"
                id="password_"
                placeholder="Password"
                autocomplete="off"
                :required="accion_form_prop=='insert' ? true : false"
                v-model="formu.password"
              />
              <label for="password_">Password</label>
            </div>

            <b-form-group label="Actividad" class="d-inline mt-3 li">
              <b-form-radio
                v-model="formu.activo"
                value="1"
                class="d-inline me-4"
              >
                Activo</b-form-radio
              >
              <b-form-radio v-model="formu.activo" value="0" class="d-inline">
               Inactivo</b-form-radio
              >
            </b-form-group>
          </div>
          <div class="col-sm-6">
            <b-form-group
              label="Permisos:"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="formu.permisos"
                :options="options"
                :aria-describedby="ariaDescribedby"
                
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
        <button class="btn btn-info mt-3" type="submit">Guardar</button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions: usuariosMapActions } = createNamespacedHelpers("usuarios");
const { mapGetters: userMapGetters } = createNamespacedHelpers("user");
export default {
  name: "UsuarioForm",
  data() {
    return {
      show: true,
      formu: {
        id_usuario: "",
        email: "",
        password: "",
        nombre_usuario: "nuevo",
        nivel: 3,
        permisos: [],
        activo: 0,
      },
      options: [
        { text: "crear/modificar usuarios", value: "1" },
        { text: "crear/modificar animales", value: "2" },
        { text: "crear/modificar tratamientos", value: "3" },
        { text: "crear/modificar visitas", value: "4" },
        { text: "crear/modificar actuaciones", value: "5" },
        { text: "crear/modificar tipos de actuación ", value: "6" },
        { text: "crear/modificar nombres de actuación", value: "7" },
        { text: "crear/modificar centros vet.", value: "8" },
        { text: "crear/modificar veterinarios", value: "9" },
        { text: "ver tareas", value: "10" },
        { text: "aplicar tareas", value: "11" },
        { text: "upload docs", value: "12" },
      ],
    };
  },
  props: {
    usuario_prop: { default: false },
    accion_form_prop: { default: "insert" },
    show_prop: { default: true },
  },
  methods: {
    ...usuariosMapActions(["UPDATE_USUARIO", "INSERT_USUARIO"]),
    cargarFormuDeProp() {
      if (this.usuario_prop != false) {
        let formu = Object.keys(this.formu);
        formu.forEach((element) => {
          this.formu[element] = this.usuario_prop[element];
        });
      }
    },
    cancelar() {
      //this.show = false;
    },
    onSubmit(event) {
      event.preventDefault();
      this.$http
        .post(
          this.$bambuvet + "/usuario-post.php",
          JSON.stringify(this.formu),
          { emulateJSON: true, withCredentials: true }
        )
        .then((res) => {
          //console.log(res.body);
          if (this.accion_form_prop == "insert") {
            this.INSERT_USUARIO(res.body);
          } else {
            this.UPDATE_USUARIO(res.body);
          }
          this.enviarCerrar()
        });
    },
    enviarCerrar() {
      this.$emit("cerrar");
    },
  },
  computed: {
    ...userMapGetters(["user_loged_in","usuario", "nombre_usuario"]),
    disabledAsignarSuperAdmin(){
      if((this.usuario.id_usuario == this.formu.id_usuario
          &&   this.usuario.nivel == 1)
          || this.usuario.nivel == 2){
        //es usuario propio
        return true
      }
      else return false
    },
    disabledAsignarAdmin(){
      if(this.usuario.id_usuario == this.formu.id_usuario
         &&  (this.usuario.nivel == 1 || this.usuario.nivel == 2)){
        //es usuario propio
        return true
      }
      else return false
    },
    disabledAsignarUsuario(){
      if(this.usuario.id_usuario == this.formu.id_usuario
      &&  (this.usuario.nivel == 2  || this.usuario.nivel == 1)){
        //es usuario propio
        return true
      }
      else return false
    },
  },
  mounted() {
    this.cargarFormuDeProp();
    this.$root.$on("bv::modal::hide", () => {
      this.enviarCerrar();
    });
    this.show = this.show_prop;
  },
};
</script>

<style lang="scss" >
input[type="checkbox"],
input[type="radio"] {
  margin-right: 0.4rem;
}
#pepe label{display: inline !important;}
</style>